@use '@queries' as *;
@use '@fixed-size' as *;
@use '@typography' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  @include min-428-break {
    gap: 10px;
  }
}

.slot {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  @include min-428-break {
    gap: 10px;
  }
}

.properties {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 8px;
  gap: 6px;
  border-radius: var(--cornerradius8);
  background: var(--cst-cards-shoping-item-background-tags);

  @include min-428-break {
    gap: 8px;
  }
}

.item-content {
  display: flex;
  padding: 10px;
  border-radius: var(--cornerradius10);
  justify-content: space-between;
  align-items: center;

  @include fixedSize(inherit, 70px);

  @include min-428-break {
    @include fixedSize(inherit, 86px);
  }

  .item-age {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .item-info {
    display: flex;
    flex-direction: column;
    gap: 1px;
    margin-left: 6px;
    .age {
      text-transform: capitalize;
      color: var(--cst-history-cards-title);
      @include typography-xs;
      @include weight-medium;

      @include min-428-break {
        @include typography-s;
      }
    }

    .title {
      color: var(--cst-history-cards-subtitle);
      @include typography-s;
      @include weight-semi-bold;

      @include min-428-break {
        @include typography-base;
      }
    }
  }
  .item-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .background {
      position: absolute;
      z-index: 0;
      @include singleFixedSize(50px);

      @include min-428-break {
        @include singleFixedSize(66px);
      }
    }

    .item {
      z-index: 1;

      @include singleFixedSize(42px);

      @include min-428-break {
        @include singleFixedSize(60px);
      }
    }
  }
}

.tag-info {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--cornerradius8);
  gap: 8px;
  padding: 8px 10px;

  &--full-w {
    @include fixedSize(inherit, 34px);
  }

  &--content {
    @include fixedSize(max-content, 34px);
  }

  &-variant {
    &--default {
      background: var(--cst-history-cards-background);
    }

    &--success {
      background: var(--cst-tag-status-background-success);
    }

    &--warn {
      background: var(--cst-tag-status-background-warn);
    }
    &--danger {
      background: var(--cst-tag-status-background-danger);
    }
  }

  @include min-428-break {
    padding: 10px;
    &--full-w {
      @include fixedSize(inherit, 41px);
    }

    &--content {
      @include fixedSize(inherit, 41px);
    }
  }
}

.text {
  color: var(--cst-history-cards-text);
  @include typography-s;
  @include weight-medium;

  &-variant {
    &--default {
      color: var(--cst-history-cards-text);
    }

    &--success {
      color: var(--cst-history-tag-status-text-success);
    }

    &--warn {
      color: var(--cst-history-tag-status-text-warn);
    }
    &--danger {
      color: var(--cst-history-tag-status-text-danger);
    }
  }

  @include min-428-break {
    @include typography-base;
  }
}
